import React from "react";

import "./index.css";

const Banner: React.FC = () => {
  return (
    <div className="v2-banner">
      <p>
        🎉 Limited Time Offer! Get 40% off with code{" "}
        <span style={{ textDecoration: "underline" }}>DISCOUNT40</span> +
        receive{" "}
        <a
          href="https://selfmailkit.com"
          style={{ fontWeight: "bold", color: "inherit" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          SelfMailKit
        </a>{" "}
        <span
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            color: "#8B0000",
          }}
        >
          free
        </span>{" "}
        when launched! 🚀
      </p>
    </div>
  );
};

export default Banner;
