import { useEffect, useMemo, useState } from "react";
import { Flex, Text, Title } from "@mantine/core";

import { PricingPackages } from "../../types/pricing";
import FreeCard from "./basic";
import GoldCard from "./gold";
import PlatinumCard from "./platinum";
import { useStyles } from "./styles";
import "./timer.css";

const PRICING_PACKAGES: PricingPackages = {
  basic: {
    name: "Basic",
    price: "180$",
    bestFor:
      "Best for very experienced developers who do not need support at all",
    features: [
      "Download the kit as a zip file",
      "Pay once, use forever",
      "All features included",
      "Unlimited personal and commercial projects",
      "Basic support",
    ],
    CTA: "Get started with Basic",
  },
  gold: {
    name: "Gold",
    price: "220$",
    includingFrom: "Everything from Basic, plus (Recommended)",
    bestFor: "Best for individuals, indie hackers, and small teams (up to 10)",
    features: [
      "Repository access for one user",
      "Lifetime updates",
      "Feature requests",
      "**Discord support**",
    ],
    CTA: "Get started with Gold",
  },
  platinum: {
    name: "Platinum",
    price: "Get in touch",
    includingFrom: "Everything from Gold, plus",
    bestFor: "Best for teams with +10 users",
    features: [
      "Repository access for all team members",
      "Priority support",
      "Prioritized feature requests",
      "Free Consulting sessions (1 hour per week) for 3 months",
      // "Build your MVP",
    ],
    CTA: "Get started with Platinum",
  },
};

const Pricing = () => {
  const { classes } = useStyles();

  const SECOND = 1000;
  const MINUTE = SECOND * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;

  const deadline = "November, 30, 2024 19:00:00";
  const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
  const [time, setTime] = useState(parsedDeadline - Date.now());

  useEffect(() => {
    const interval = setInterval(
      () => setTime(parsedDeadline - Date.now()),
      1000
    );

    return () => clearInterval(interval);
  }, [parsedDeadline]);

  return (
    <>
      <section id="testimonials">
        <div
          className="senja-embed hero-testimonial-full"
          data-id="08764f7b-567a-4457-879a-843d17bd8b64"
          data-lazyload="false"
        ></div>
      </section>
      <section id="pricing">
        <div className={classes.title}>
          <Title order={2} size="h1" align="center" mb="lg">
            Pricing
          </Title>

          <Text size="lg" align="center" m="auto" mb="xl" maw={700}>
            Pay once, deploy now, use forever
            {/* </Text>

  <div className="timer-box">
    <div className="timer">
      {Object.entries({
        Days: time / DAY,
        Hours: (time / HOUR) % 24,
        Minutes: (time / MINUTE) % 60,
        Seconds: (time / SECOND) % 60,
      }).map(([label, value]) => (
        <div key={label} className="col-4">
          <div className="box">
            <p>{`${Math.floor(value)}`.padStart(2, "0")}</p>
            <span className="timer-text">{label}</span>
          </div>
        </div>
      ))}
    </div>
  </div>
  <Text
    style={{ display: "flex", justifyContent: "center" }}
    size="lg"
    align="center"
    m="auto"
    mt="md"
    mb="xl"
    maw={700}
  >
    <IconGift className="discount-icon" size={24} />{" "}
    <span className="discount-text"> 50% off for a limited time</span> */}
          </Text>
        </div>

        <Flex
          justify="space-between"
          direction={{
            base: "column",
            md: "row",
          }}
          gap="xl"
          my="xl"
        >
          <FreeCard package_data={PRICING_PACKAGES.basic} />
          <GoldCard package_data={PRICING_PACKAGES.gold} />
          <PlatinumCard package_data={PRICING_PACKAGES.platinum} />
        </Flex>
      </section>
    </>
  );
};

export default Pricing;
